/* eslint-disable no-undef */
/* eslint-disable consistent-return */
/* eslint-disable no-prototype-builtins */
/* eslint camelcase: [0, {properties: never}] */

'use strict';

var tealium = require('./../pages/tealium');

/**
 * Common analytics data used in chat events
 * @returns {Object} having common properties used across chat modal events
 */
function commonAnalyticsData() {
    if (window.utag_data) {
        var commonProps = {
            site: window.utag_data.site,
            site_section: window.utag_data.site_section,
            page_name: window.utag_data.page_name,
            page_group: window.utag_data.page_type,
            page_context_type: window.utag_data.page_context_type,
            page_context_title: window.utag_data.page_context_title,
            login_status: window.utag_data.login_status,
            site_type: window.utag_data.site_type,
            user_id: window.utag_data.user_id
        };
        return commonProps;
    }
}

/**
 * GA Analytics event for Sort By filter on PLP page for desktop
 */
function sortByDropDownDesktop() {
    $(document).on('change', '.js-sort-by-dropdown', function () {
        var $selectDropdown = $(this);
        var selectedOptionID = $selectDropdown.find('option:selected').data('id') || null;
        var selectedOptionText = $selectDropdown.find('option:selected').text().trim() || null;
        if (selectedOptionID !== null) {
            var commonProps = commonAnalyticsData();
            var eventObj = {
                element_type: $selectDropdown[0].nodeName,
                element_text: selectedOptionText,
                event_type: 'sort_applied',
                sort_selection: selectedOptionID,
                page_location: window.location.pathname
            };
            var pdpEventData = Object.assign(commonProps, eventObj);
            tealium.triggerEvent(pdpEventData);
        }
    });
}

/**
 * GA Analytics event for Sort By filter on PLP page for mobile
 */
function sortByRadioButtonMobile() {
    $(document).on('click', '.js-sort-by-radio', function () {
        var $selectRadioBtn = $(this);
        var selectedOptionID = $selectRadioBtn.data('id') || null;
        var selectedOptionText = $selectRadioBtn.text().trim() || null;
        if (selectedOptionID !== null) {
            var commonProps = commonAnalyticsData();
            var eventObj = {
                element_type: $selectRadioBtn[0].nodeName,
                element_text: selectedOptionText,
                event_type: 'sort_applied',
                sort_selection: selectedOptionID,
                page_location: window.location.pathname
            };
            var pdpEventData = Object.assign(commonProps, eventObj);
            tealium.triggerEvent(pdpEventData);
        }
    });
}

module.exports = {
    sortByDropDownDesktop: sortByDropDownDesktop,
    sortByRadioButtonMobile: sortByRadioButtonMobile
};
