/* eslint-disable no-undef */
/* eslint-disable consistent-return */
/* eslint-disable no-prototype-builtins */

/* eslint camelcase: [0, {properties: "never"}] */

'use strict';

var tealium = require('./tealium');
var TRACKING_DIGITALRETAIL = 'tracking:digitalretail';

/**
 * openModalViewEvent fire view event on content component product click
 * @param {string} pid - gettting parameter
 */

var digitalretailtealium = {
    init: function () {
        $(window).on('load', function () {
            $(document).on(TRACKING_DIGITALRETAIL, '#scan-product-details', function () {
                if (window.utag_data) {
                    var scanproductretail = {
                        event_type: 'in_store_product_details',
                        page_name: window.utag_data.page_name,
                        ga_category: 'Product Details',
                        ga_action: 'View Product Details',
                        ga_label: $(this).attr('data-productid')
                    };
                    tealium.triggerEvent(scanproductretail);
                }
            });

            $(document).on(TRACKING_DIGITALRETAIL, '#product-ratings', function () {
                var galabel = $('#scan-product-details').length ? $('#scan-product-details').attr('data-productid') : '';
                if (window.utag_data) {
                    var emptyresultratings = '';

                    if ($(this).attr('data-productid') === null || $(this).attr('data-productid') === '') {
                        tealium.triggerEvent(emptyresultratings);
                    } else {
                        var showproductratings = {
                            event_type: 'in_store_product_ratings',
                            page_name: window.utag_data.page_name,
                            ga_category: 'Product Ratings',
                            ga_action: 'View Product Ratings',
                            ga_label: galabel
                        };
                        tealium.triggerEvent(showproductratings);
                    }
                }
            });

            // Onboarding Screen – Skip Introduction and Skip Location
            $(document).on(TRACKING_DIGITALRETAIL, '#retail-instore-skip', function () {
                var instoreSkipAction = 'Skip Introduction';

                // check if user hits the skip button on the location slide
                if ($('.locator-slide').hasClass('active')) {
                    instoreSkipAction = 'Skip Store Confirmation';
                }

                if (window.utag_data) {
                    var showinstoreskip = {
                        event_type: 'in_store_skip',
                        page_name: window.utag_data.page_name,
                        ga_category: 'Store Experience',
                        ga_action: instoreSkipAction,
                        ga_label: ''
                    };
                    tealium.triggerEvent(showinstoreskip);
                }
            });

            // Onboarding Screen – Next Introduction
            $(document).on(TRACKING_DIGITALRETAIL, '#retail-instore-next', function () {
                if (window.utag_data) {
                    var showinstorenext = {
                        event_type: 'in_store_next',
                        page_name: window.utag_data.page_name,
                        ga_category: 'Store Experience',
                        ga_action: 'Next Introduction Slide',
                        ga_label: ''
                    };
                    tealium.triggerEvent(showinstorenext);
                }
            });

            // Confirm Location - Share
            $(document).on('shareLocation', function (e, data) {
                if (window.utag_data) {
                    var showinstoresharelocation = {
                        event_type: 'in_store_share_location',
                        page_name: window.utag_data.page_name,
                        ga_category: 'Store Experience',
                        ga_action: 'Share Location',
                        ga_label: data.location
                    };
                    tealium.triggerEvent(showinstoresharelocation);
                }
            });

            // Confirm Location – Enter Zip Code
            $(document).on(TRACKING_DIGITALRETAIL, '.instore-enter-location', function () {
                if (window.utag_data) {
                    var showinstoreenterlocation = {
                        event_type: 'in_store_enter_city_state',
                        page_name: window.utag_data.page_name,
                        ga_category: 'Store Experience',
                        ga_action: 'Enter City, State',
                        ga_label: ''
                    };
                    tealium.triggerEvent(showinstoreenterlocation);
                }
            });

            // Confirm Location – Enter Zipcode for list of stores
            $(document).on(TRACKING_DIGITALRETAIL, '#submit-location-search', function () {
                var usersLocation = $('#store-city').val() + ',' + $('#store-state').val();
                if (window.utag_data) {
                    var showinstoreconfirmstore = {
                        event_type: 'in_store_confirm_store',
                        page_name: window.utag_data.page_name,
                        ga_category: 'Store Experience',
                        ga_action: 'Click Store',
                        ga_label: usersLocation
                    };
                    tealium.triggerEvent(showinstoreconfirmstore);
                }
            });

            // Confirm Location – Choose a store from list
            $(document).on(TRACKING_DIGITALRETAIL, '.instore-set-location', function () {
                var storeAddress = $(this).data('storeaddress1');
                var storeCity = $(this).data('storecity');
                var storeState = $(this).data('storestate');
                var storeZip = $(this).data('storepostal');

                var storeLocation = storeAddress + ',' + storeCity + ',' + storeState + ' ' + storeZip;
                if (window.utag_data) {
                    var showinstoreenterzipcode = {
                        event_type: 'in_store_set_location',
                        page_name: window.utag_data.page_name,
                        ga_category: 'Store Experience',
                        ga_action: 'Set Location',
                        ga_label: storeLocation
                    };
                    tealium.triggerEvent(showinstoreenterzipcode);
                }
            });

            $(document).on(TRACKING_DIGITALRETAIL, '#qr-scanned-state', function () {
                if (window.utag_data) {
                    var storecardclick = {
                        event_type: 'in_store_card_click',
                        page_name: window.utag_data.page_name,
                        ga_category: 'Store Experience',
                        ga_action: 'Card Click',
                        ga_label: $(this).attr('data-productid')
                    };
                    tealium.triggerEvent(storecardclick);
                }
            });

            $(document).on(TRACKING_DIGITALRETAIL, '#modal-enter-location', function () {
                var productId = $('#scan-product-details').length ? $('#scan-product-details').attr('data-productid') : '';
                if (window.utag_data) {
                    var instoresetlocation = {
                        event_type: 'in_store_tap_location',
                        page_name: window.utag_data.page_name,
                        ga_category: 'Location',
                        ga_action: 'Turn On Location',
                        ga_label: productId
                    };
                    tealium.triggerEvent(instoresetlocation);
                }
            });

            // Landing Page - Product Inventory tile
            $(document).on(TRACKING_DIGITALRETAIL, '#product-inventory-button', function () {
                var productId = $('#scan-product-details').length ? $('#scan-product-details').attr('data-productid') : '';
                if (window.utag_data) {
                    var instoreproductinventory = {
                        event_type: 'in_store_product_inventory',
                        page_name: window.utag_data.page_name,
                        ga_category: 'Product Inventory',
                        ga_action: 'Product Inventory Search',
                        ga_label: productId
                    };
                    tealium.triggerEvent(instoreproductinventory);
                }
            });

            // Customer Preferences - size selection
            $(document).on(TRACKING_DIGITALRETAIL, '.variation-attribute', function () {
                var genderDataTab = $('.tab-pane.active').data('gender-tab');
                var productType = $(this).data('product-type');
                var eventType = '';
                var eventAction = '';

                if (window.utag_data) {
                    if (productType === 'shoesize') {
                        eventType = 'size';
                        eventAction = 'Shoe';
                    }
                    if (productType === 'widthsize') {
                        eventType = 'width';
                        eventAction = 'Width';
                    }
                    if (productType === 'topsize') {
                        eventType = 'top_size';
                        eventAction = 'Top';
                    }
                    if (productType === 'pantsize') {
                        eventType = 'bottom_size';
                        eventAction = 'Bottom';
                    }
                    if (productType === 'brasize') {
                        eventType = 'bra_size';
                        eventAction = 'Bra';
                    }

                    var instoreinventorysize = {
                        event_type: 'in_store_inventory_' + eventType,
                        page_name: window.utag_data.page_name,
                        ga_category: 'Product Inventory',
                        ga_action: 'Product Inventory ' + eventAction + ' Size Click',
                        ga_label: genderDataTab + ' ' + $(this).data('attr-value')
                    };
                    tealium.triggerEvent(instoreinventorysize);
                }
            });

            // Customer Preferences - "Scan for Inventory" button
            $(document).on(TRACKING_DIGITALRETAIL, '#scan-inventory-analytics', function () {
                if (window.utag_data) {
                    var instorescaninventory = {
                        event_type: 'in_store_scan_inventory',
                        page_name: window.utag_data.page_name,
                        ga_category: 'Product Inventory Scan',
                        ga_action: 'Scan Inventory Click',
                        ga_label: ''
                    };
                    tealium.triggerEvent(instorescaninventory);
                }
            });
        });
    }
};

digitalretailtealium.init();
