/* eslint-disable no-undef */

'use strict';

var tealium = require('./tealium');
var device = tealium.getDevice();
var utils = require('./utils');

var login = {
    init: function () {
        $('.change-password-form button[name=save]').on('click', function () {
            if (window.utag_data) {
                var savePasswordObj = {
                    event_type: 'my_account_change',
                    change_action: 'edit_password',
                    event_message: 'Edit Password',
                    ga_action: 'Profile',
                    ga_label: 'Edit Password'
                };
                utils.fireTealium(savePasswordObj);
            }
        });

        $(document).on('login:submit', 'form.login, form.login-modal, form.login-embed-checkout', function () {
            var isLoginPage = $(this).closest('form').hasClass('login-modal');
            if (window.utag_data) {
                var eventObj = {
                    event_type: isLoginPage ? 'login_overlay' : 'login_top_nav',
                    login_type: 'Traditional'
                };
                utils.fireTealium(eventObj);
            }
        });

        $(document).on('login:success', 'form.login, form.login-modal, form.login-embed-checkout', function () {
            if (window.utag_data) {
                var rememberMeVal = document.getElementById('rememberMe').checked ? 'yes' : 'no';
                var eventObj = {
                    event_type: 'login_complete',
                    login_type: 'Traditional',
                    remember_me_selected: rememberMeVal
                };
                utils.fireTealium(eventObj);
            }
        });

        $(document).on('login:register:success', 'form.registration, form.registration-modal, form.register-embed-checkout', function () {
            if (window.utag_data) {
                var isCheckboxChecked = false;

                if (document.getElementById('email-subscription')) {
                    isCheckboxChecked = document.getElementById('email-subscription').checked;
                }

                isCheckboxChecked = isCheckboxChecked ? 'yes' : 'no';
                var createAccount = {
                    event_type: 'create_account',
                    login_type: 'create account',
                    event_message: 'login | ' + device,
                    email_signup_checkbox: isCheckboxChecked
                };
                utils.fireTealium(createAccount);
            }
        });

        $('.login-page form[name=login-oauth-form] .facebook-login').on('click', function () {
            var isLoginPage = $(this).closest('form').hasClass('fb-login-modal');
            if (window.utag_data) {
                var eventObj = {
                    event_type: isLoginPage ? 'login_overlay_facebook' : 'login_top_nav_facebook',
                    login_type: 'Facebook'
                };
                utils.fireTealium(eventObj);
            }
        });

        $('.reset-password-form-login #submitEmailButton').on('click', function () {
            if (window.utag_data) {
                var resetPassword = {
                    event_type: 'my_account_change',
                    change_action: 'reset_password',
                    event_message: 'Reset Password',
                    ga_action: 'Reset Password',
                    ga_label: ''
                };
                utils.fireTealium(resetPassword);
            }
        });

        $('.login-page form[name=login-oauth-form] .facebook-register').on('click', function () {
            if (window.utag_data) {
                var createAccountFacebook = {
                    event_type: 'create_account',
                    login_type: 'facebook',
                    event_message: 'login | ' + device
                };
                utils.fireTealium(createAccountFacebook);
            }
        });
    }
};

login.init();
