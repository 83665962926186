/* eslint-disable no-undef */

'use strict';

var tealium = require('./tealium');
var TRACKING_GLOBAL = 'tracking:global';

var rewards = {
    init: function () {
        $('body').on('rewards:join', function () {
            if (!window.utag_data) {
                return;
            }

            var joinObj = {
                event_type: 'mynb Join',
                page_name: window.utag_data.page_name,
                event_message: 'myNB Join | Join',
                ga_action: 'myNB | Join',
                ga_label: ''
            };
            tealium.triggerEvent(joinObj);
        });

        $(document).on(TRACKING_GLOBAL, '.see-rewards', function () {
            if (window.utag_data) {
                var seeRewardsObj = {
                    event_type: 'see_rewards',
                    page_name: window.utag_data.page_name,
                    ga_action: 'myNB | See Rewards',
                    ga_label: ''
                };
                tealium.triggerEvent(seeRewardsObj);
            }
        });

        $(document).on(TRACKING_GLOBAL, '.see-activity', function () {
            if (window.utag_data) {
                var seeActivityObj = {
                    event_type: 'see_activity',
                    page_name: window.utag_data.page_name,
                    ga_action: 'myNB | See Activity',
                    ga_label: ''
                };
                tealium.triggerEvent(seeActivityObj);
            }
        });

        $('body').on('rewards:redeemTile', function (event, data) {
            if (window.utag_data) {
                var redeemObj = {
                    event_type: 'mynb redeem',
                    page_name: window.utag_data.page_name,
                    event_message: 'redeem',
                    ga_action: 'myNB | Redeem',
                    ga_label: data
                };
                tealium.triggerEvent(redeemObj);
            }
        });

        $('form.complete-reg').on('registration:complete', function () {
            if (window.utag_data) {
                var completeRegistration = {
                    event_type: 'create_password',
                    page_name: window.utag_data.page_name,
                    event_message: 'Create Password',
                    ga_action: 'myNB | Create Password',
                    ga_label: ''
                };
                tealium.triggerEvent(completeRegistration);
            }
        });

        $(document).on(TRACKING_GLOBAL, '.my-nbrewards-container .store-rewards', function () {
            if (window.utag_data) {
                var joinObj = {
                    event_type: 'myRewards_join',
                    login_type: window.utag_data.login_type,
                    page_name: window.utag_data.page_name,
                    event_message: 'login start | ' + tealium.getDevice(),
                    ga_action: 'Store Locator',
                    ga_label: 'Join NB Rewards'
                };
                tealium.triggerEvent(joinObj);
            }
        });
    }
};

rewards.init();
