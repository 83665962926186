/* eslint-disable no-undef */

'use strict';

var tealium = require('./tealium');
var TRACKING_PRODUCTDETAIL = 'tracking:productdetail';
var productId;

/**
 * Common product analytics data
 * @param {Object} data Product data object as a parameter.
 * @returns {Object} having common properties used across PDP events
 */
function productCommonProps(data) {
    return {
        product_name: data.product_name,
        product_brand: data.product_brand,
        product_unit_price: data.product_unit_price,
        product_sku: data.product_sku,
        product_style_number: data.product_style_number,
        product_id: data.product_master,
        adobe_product_id: data.adobe_product_id,
        product_master: data.product_master,
        product_category: data.product_category,
        product_subcategory: data.product_subcategory,
        product_vat: data.product_vat,
        product_gender: data.product_gender,
        product_color: data.product_color,
        product_size: data.product_size,
        product_width: data.product_width,
        product_gbu: data.product_gbu,
        product_line: data.product_line,
        product_type: data.product_type,
        product_silhouette: data.product_silhouette,
        category_path: data.category_path,
        original_product_unit_price: data.original_product_unit_price,
        color_variation: data.product_color,
        width_variation: data.product_width,
        size_variation: data.product_size,
        item_list_name: data.category_path,
        item_list_id: data.item_list_id,
        item_category: data.product_category,
        item_category2: data.product_line,
        item_category3: data.product_gender,
        item_category4: data.product_color,
        item_category5: data.product_style_number
    };
}

var productDetails = {
    init: function () {
        $('.product-detail button[data-containertype=not-available-yet]').click(function () {
            if (window.utag_data) {
                productId = $('.container.product-detail.product-wrapper').data('pid');
                var productInfo = tealium.getProductDetails(productId);
                productInfo.then(function (data) {
                    var productProps = productCommonProps(data);
                    var notifyObj = {
                        event_type: 'notify_me_not_available',
                        event_message: 'Notify Me',
                        page_name: window.utag_data.page_name,
                        adobe_user_flow: 'product detail',
                        ga_action: 'Notify Me | Not Available',
                        ga_label: productId
                    };
                    var pdpEventData = Object.assign(productProps, notifyObj);
                    tealium.triggerEvent(pdpEventData);
                });
            }
        });

        $('.product-detail  button[data-containertype=out-of-stock-notify]').click(function () {
            if (window.utag_data) {
                productId = $('.container.product-detail.product-wrapper').data('pid');
                var productInfo = tealium.getProductDetails(productId);
                productInfo.then(function (data) {
                    var productProps = productCommonProps(data);
                    var notifyObj = {
                        event_type: 'notify_me_sold_out',
                        event_message: 'Notify Me',
                        page_name: window.utag_data.page_name,
                        adobe_user_flow: 'product detail',
                        ga_action: 'Notify Me | Sold Out',
                        ga_label: productId,
                        product_quantity: data.product_quantity
                    };
                    var pdpEventData = Object.assign(productProps, notifyObj);
                    tealium.triggerEvent(pdpEventData);
                });
            }
        });

        $(document).on(TRACKING_PRODUCTDETAIL, '.subscribe-form .subscribe-btn', function () {
            var customerEmail = $(document).find('input.subscriber-email').val();
            var elemText = $(this).text().trim();
            var attrName = $(this)[0].nodeName;
            if (window.utag_data && window.utag_load_data) {
                var loadData = window.utag_load_data;
                var notifySubscribeObj = {
                    customer_email: customerEmail,
                    event_message: 'Notify Me Subscribe',
                    page_name: loadData.page_name,
                    ga_action: 'Notify Me | Subscribe',
                    ga_label: '',
                    element_type: attrName,
                    element_text: elemText,
                    page_type: loadData.page_type !== undefined ? loadData.page_type : window.utag_data.page_type,
                    event_type: 'email_signup',
                    event_detail: 'Notify Me | Subscribe',
                    page_location: window.location.pathname
                };
                tealium.triggerEvent(notifySubscribeObj);
            }
        });

        $(document).on(TRACKING_PRODUCTDETAIL, '.bisn-form .bisn-success-button', function () {
            if (window.utag_data && window.utag_load_data) {
                var elemText = $(this).text().trim();
                var attrName = $(this)[0].nodeName;
                productId = $('.container.product-detail.product-wrapper').data('pid');
                var productInfo = tealium.getProductDetails(productId);
                productInfo.then(function (data) {
                    var productProps = productCommonProps(data);
                    var loadData = window.utag_load_data;
                    var notifySubscribeObjBisn = {
                        event_message: 'BISN Notify Me Subscribe',
                        ga_action: 'BISN Notify Me | Subscribe',
                        ga_label: '',
                        element_type: attrName,
                        element_text: elemText,
                        page_name: loadData.page_name,
                        event_type: 'email_signup',
                        event_detail: 'BISN Notify Me | Subscribe',
                        page_location: window.location.pathname,
                        site: loadData.site,
                        site_section: loadData.site_section,
                        page_group: loadData.page_type !== undefined ? loadData.page_type : window.utag_data.page_type,
                        page_context_type: loadData.page_context_type,
                        page_context_title: loadData.page_context_title,
                        login_status: loadData.login_status,
                        site_type: loadData.site_type,
                        user_id: loadData.user_id,
                        item_name: loadData.item_name,
                        item_id: loadData.item_id,
                        product_list: loadData.category_path,
                        product_impression_id: loadData.category_path,
                        product_quantity: data.product_quantity,
                        product_silhouette: data.product_silhouette,
                        item_category: data.product_category,
                        item_category2: data.product_line,
                        item_category3: data.product_gender,
                        item_category4: data.product_color,
                        item_category5: data.product_style_number
                    };
                    var pdpEventData = Object.assign(productProps, notifySubscribeObjBisn);
                    tealium.triggerEvent(pdpEventData);
                });
            }
        });

        $(document).on(TRACKING_PRODUCTDETAIL, '#pr-reviewdisplay .pr-snippet-write-review-link, #pr-reviewsnippet .pr-snippet-write-review-link', function () {
            var pid = $('.reviews-plugin #pr-read-meta').data('prdisplaydata').page_id;
            if (window.utag_data) {
                var reviewsObj = {
                    event_type: 'write_review',
                    page_name: window.utag_data.page_name,
                    event_message: 'Write Review',
                    ga_action: 'Write Review',
                    ga_label: pid
                };
                tealium.triggerEvent(reviewsObj);
            }
        });

        $('#pr-reviewsnippet').on('product:reviewsCount', function () {
            var pid = $('.reviews-plugin #pr-read-meta').data('prdisplaydata').page_id;
            if (window.utag_data) {
                var wdata = window.utag_data.page_type;
                if (wdata === 'digitalretail') {
                    var productReviews = {
                        event_type: 'in_store_reviews',
                        page_name: window.utag_data.page_name,
                        ga_category: 'Store Experience',
                        ga_action: 'Reviews Click',
                        ga_label: window.utag_data.product_id[0]
                    };
                    tealium.triggerEvent(productReviews);
                } else {
                    var seeReviewObj = {
                        event_type: 'Product Reviews',
                        page_name: window.utag_data.page_name,
                        event_message: 'View All Reviews',
                        ga_action: 'View Reviews',
                        ga_label: pid
                    };
                    tealium.triggerEvent(seeReviewObj);
                }
            }
        });

        $(document).on(TRACKING_PRODUCTDETAIL, '.robis-button-action.upc-available', function () {
            if (window.utag_data) {
                productId = $('.container.product-detail.product-wrapper').data('pid');
                var productInfo = tealium.getProductDetails(productId);
                productInfo.then(function (data) {
                    var gaTemp = window.utag_data.customer_zip ? data.product_name + ', ' + window.utag_data.customer_zip : data.product_name;
                    var productProps = productCommonProps(data);
                    var robisObj = {
                        event_type: 'Robis Launch',
                        page_name: window.utag_data.page_name,
                        link_text: 'reserve online ready in store',
                        customer_zip: window.utag_data.customer_zip,
                        ga_action: 'Robis Launch',
                        ga_label: gaTemp,
                        product_quantity: data.product_quantity
                    };
                    var pdpEventData = Object.assign(productProps, robisObj);
                    tealium.triggerEvent(pdpEventData);
                });
            }
        });

        $(document).on(TRACKING_PRODUCTDETAIL, '.product-detail .add-to-wishlist .add-to-wish-list', function () {
            productId = $('.container.product-detail.product-wrapper').data('pid');
            var productInfo = tealium.getProductDetails(productId);
            var elementText = $(this).find('span').text().trim();
            var elementType = $(this)[0].nodeName;

            productInfo.then(function (productData) {
                $(document).trigger('wishlist:add-product', {
                    product_data: productData,
                    event_type: 'pdp_move_to_wishlist',
                    ga_action: 'Move to Wishlist | PDP',
                    ga_label: productId,
                    element_text: elementText,
                    element_type: elementType
                });

                if (window.utag_data && window.utag_data.user_authenticated === 'false') {
                    $(document).trigger('login-modal-open', ['login_popupin', 'wishlist_pdp']);
                }
            });
        });

        $(document).on(TRACKING_PRODUCTDETAIL, '.cart-and-ipay .add-to-cart, .add-to-cart-sticky .add-to-cart', function () {
            var preorder = $(this).attr('data-preorder');
            var addTocart = {};
            var parentElem = $(this).parent();
            var elemText = $(this).find('span').text().trim();
            var attrName = $(this)[0].nodeName;
            var loadData = window.utag_load_data;
            var energyLaunchProduct = $('[is-energy-launch]');

            if (window.utag_data) {
                productId = $('.container.product-detail.product-wrapper').data('pid');
                var productInfo = tealium.getProductDetails(productId);
                productInfo.then(function (data) {
                    var productProps = productCommonProps(data);
                    addTocart = {
                        event_type: 'cart_add_pdp',
                        page_name: window.utag_data.page_name,
                        adobe_user_flow: 'product detail',
                        product_currency: data.product_currency,
                        order_currency: data.product_currency,
                        product_variant_id: data.product_variant_id,
                        product_quantity: data.product_quantity,
                        ga_action: 'Add to Cart | PDP',
                        ga_label: data.product_master,
                        site: window.utag_data.site,
                        site_section: 'product detail',
                        page_type: loadData.page_type !== undefined ? loadData.page_type : window.utag_data.page_type,
                        page_group: 'product',
                        page_context_type: window.utag_data.page_context_type,
                        page_context_title: window.utag_data.page_context_title,
                        login_status: window.utag_data.login_status,
                        site_type: window.utag_data.site_type,
                        element_type: attrName,
                        element_text: elemText,
                        user_id: window.utag_data.user_id,
                        item_name: data.product_name,
                        item_id: data.product_id,
                        item_list_id: window.utag_data.item_list_id,
                        price: data.product_unit_price,
                        item_brand: data.product_brand,
                        item_category: data.product_category,
                        item_id_price: data.product_unit_price,
                        item_variant: data.product_variant_id,
                        quantity: data.product_quantity,
                        event_detail: '',
                        item_list_name: data.category_path,
                        product_list: data.category_path,
                        // energy launch specific code
                        is_launch_event: window.sitePrefs.energyLaunchEnabled === true && energyLaunchProduct.length >= 1 ? 'true' : 'false'
                    };

                    if (parentElem.hasClass('cart-and-ipay')) {
                        addTocart.event_detail = 'pdp';
                    } else if (parentElem.hasClass('sticky-content')) {
                        addTocart.event_detail = 'mini_cart';
                    }
                    // If product variant id is present then trigger the tealium request
                    if (data.product_variant_id.length) {
                        if (preorder === 'true') {
                            addTocart.event_type = 'pre_order';
                            addTocart.event_message = 'Pre Order';
                            addTocart.ga_action = 'Pre Order';
                            addTocart.ga_label = productId;
                        }
                    } else {
                        addTocart.event_type = 'cart_add_error';
                        addTocart.ga_action = 'Add to Cart Error | PDP';
                        addTocart.ga_label = data.product_master;
                    }
                    var pdpEventData = Object.assign(productProps, addTocart);
                    tealium.triggerEvent(pdpEventData);
                });
            }
        });

        $(document).on(TRACKING_PRODUCTDETAIL, '.cart-and-ipay .add-to-cart-global', function () {
            if (window.utag_data) {
                productId = $('.container.product-detail.product-wrapper').data('pid');
                var productInfo = tealium.getProductDetails(productId);
                productInfo.then(function (data) {
                    var productProps = productCommonProps(data);
                    var addTocart = {
                        event_type: 'cart_add_flyout',
                        page_name: window.utag_data.page_name,
                        adobe_user_flow: 'product detail',
                        ga_action: 'Add to Cart | Flyout',
                        ga_label: productId,
                        product_quantity: data.product_quantity
                    };
                    var pdpEventData = Object.assign(productProps, addTocart);
                    tealium.triggerEvent(pdpEventData);
                });
            }
        });

        /**
         * This will act as a helper function for the variation selectors. It is in charge of capturing analytics data from the PDP to be sent off
         * @param {Object} element - This is the targeted element
         * @param {string} elementText - This is the target element text -> 'select color', 'select width', or 'select size'
         * @param {string} variantType - This is the variant type -> Style variant, Size variant, Width variant
         */
        function sendVariantValuesForAnalytics(element, elementText, variantType) {
            if (window.utag_data) {
                var productInfo = tealium.getProductDetails(window.utag_data.product_id);
                productInfo.then(function (data) {
                    var variationValue = 'data-variation-value';
                    var sizeVariantDisplayValue = $('.variation-attribute.size-attribute.selected').attr(variationValue);
                    var styleVariantDisplayValue = $('.variation-attribute.style-attribute.selected').attr(variationValue);
                    var widthVariantDisplayValue = $('.variation-attribute.width-attribute.selected').attr(variationValue);
                    var variantTypeObject = {
                        color_variation: styleVariantDisplayValue,
                        element_type: element[0].nodeName,
                        element_text: element.text().trim(),
                        event_type: 'pdp_click',
                        ga_action: elementText,
                        page_name: window.utag_data.page_name,
                        product_color: styleVariantDisplayValue,
                        product_size: sizeVariantDisplayValue,
                        product_width: widthVariantDisplayValue,
                        size_variation: sizeVariantDisplayValue,
                        width_variation: widthVariantDisplayValue
                    };

                    if (variantType === 'style') {
                        variantTypeObject.event_message = elementText + ' | ' + styleVariantDisplayValue;
                        variantTypeObject.ga_label = styleVariantDisplayValue;
                    } else if (variantType === 'size') {
                        var isUnselectable = element.hasClass('unselectable');
                        variantTypeObject.product_quantity = data.product_quantity;
                        if (isUnselectable) {
                            variantTypeObject.element_text = element.attr(variationValue);
                            variantTypeObject.event_message = 'unavailable_size | ' + element.attr(variationValue);
                            variantTypeObject.event_type = 'select_size_unavailable';
                            variantTypeObject.ga_label = element.attr(variationValue);
                            variantTypeObject.product_size = element.attr(variationValue);
                            variantTypeObject.size_variation = element.attr(variationValue);
                        } else {
                            variantTypeObject.element_text = sizeVariantDisplayValue;
                            variantTypeObject.event_message = elementText + ' | ' + sizeVariantDisplayValue;
                            variantTypeObject.event_type = 'pdp_click';
                            variantTypeObject.ga_label = sizeVariantDisplayValue;
                            variantTypeObject.product_size = sizeVariantDisplayValue;
                            variantTypeObject.size_variation = sizeVariantDisplayValue;
                        }
                    } else if (variantType === 'width') {
                        variantTypeObject.event_message = elementText + ' | ' + widthVariantDisplayValue;
                        variantTypeObject.ga_label = widthVariantDisplayValue;
                    }

                    var productProps = productCommonProps(data);
                    var pdpEventData = $.extend(productProps, variantTypeObject);
                    tealium.triggerEvent(pdpEventData);
                });
            }
        }

        $(document).on(TRACKING_PRODUCTDETAIL, '#productDetails [data-attr="width-value"]', function () {
            sendVariantValuesForAnalytics($(this), 'select width', 'width');
        });

        $(document).on(TRACKING_PRODUCTDETAIL, '#productDetails [data-attr="size-value"]', function () {
            sendVariantValuesForAnalytics($(this), 'select size', 'size');
        });

        $(document).on(TRACKING_PRODUCTDETAIL, '#productDetails  [data-attr="style-value"]', function () {
            sendVariantValuesForAnalytics($(this), 'select color', 'style');
        });

        // Gift card
        $(document).on('giftcard:checkbalance', function () {
            if (!window.utag_data) {
                return;
            }

            var checkBalanceObj = {
                event_type: 'check_balance',
                page_name: window.utag_data.page_name,
                event_message: 'Check Balance'
            };
            tealium.triggerEvent(checkBalanceObj);
        });

        $(document).on('giftcard:addgiftcard', function (event, result) {
            if (!window.utag_data) {
                return;
            }

            var productInfo = tealium.getProductDetails(result.pid);
            productInfo.then(function (data) {
                var productProps = productCommonProps(data);
                var giftcardObj = {
                    event_type: result.event_type,
                    page_name: window.utag_data.page_name,
                    product_quantity: result.quantity ? result.quantity : data.product_quantity,
                    ga_action: result.ga_action,
                    ga_label: result.pid
                };
                var pdpEventData = Object.assign(productProps, giftcardObj);
                tealium.triggerEvent(pdpEventData);
            });
        });

        $(document).on(TRACKING_PRODUCTDETAIL, '.selector-container .variant-select-wrap.size', function () {
            var size = $(this).find('.variant-select.size .selected').attr('data-value');
            if (window.utag_data) {
                var sizeObj = {
                    event_type: 'customizer_select_size',
                    page_name: window.utag_data.page_name,
                    event_message: 'Customizer Select Size | ' + size,
                    ga_action: 'Select Size',
                    ga_label: size
                };
                tealium.triggerEvent(sizeObj);
            }
        });

        $(document).on(TRACKING_PRODUCTDETAIL, '.selector-container .variant-select-wrap.width', function () {
            var width = $(this).find('.variant-select.width').attr('data-value');
            if (window.utag_data) {
                var widthObj = {
                    event_type: 'customizer_select_width',
                    page_name: window.utag_data.page_name,
                    event_message: 'Customizer Select Width | ' + width,
                    ga_action: 'Select Width',
                    ga_label: width
                };
                tealium.triggerEvent(widthObj);
            }
        });

        $(document).on('cutomizer:color', function (event, data) {
            if (window.utag_data) {
                var colorObj = {
                    event_type: 'Customizer_select_color',
                    page_name: window.utag_data.page_name,
                    event_message: 'Customizer Select Color | ' + data.color,
                    ga_action: 'Select Color',
                    ga_label: data.color
                };
                tealium.triggerEvent(colorObj);
            }
        });

        $(document).on('customizer:view', function (event, data) {
            if (window.utag_data) {
                var viewObj = {
                    event_type: 'customize_action_view',
                    page_name: window.utag_data.page_name,
                    link_text: window.utag_data.page_name,
                    ga_action: data.name,
                    ga_label: ''
                };
                tealium.triggerEvent(viewObj);
            }
        });

        $(document).on('customizer:parts', function (event, data) {
            if (window.utag_data) {
                var viewObj = {
                    event_type: 'customize_action',
                    page_name: window.utag_data.page_name,
                    link_text: window.utag_data.page_name,
                    ga_action: data.category,
                    ga_label: data.category
                };
                tealium.triggerEvent(viewObj);
            }
        });

        $(document).on(TRACKING_PRODUCTDETAIL, '.controls-view .sign.action-button', function () {
            if (window.utag_data) {
                var signinObj = {
                    event_type: 'customizer_sign_it',
                    page_name: window.utag_data.page_name,
                    link_text: 'Sign It',
                    event_message: 'Sign It',
                    ga_action: 'Add Text',
                    ga_label: ''
                };
                tealium.triggerEvent(signinObj);
            }
        });

        $(document).on('customizer:addtocart', function (event, result) {
            if (window.utag_data) {
                var productInfo = tealium.getProductDetails(result.pid);
                productInfo.then(function (data) {
                    var productProps = productCommonProps(data);
                    var addCartObj = {
                        event_type: 'cart_add_customizer',
                        page_name: window.utag_data.page_name,
                        adobe_user_flow: 'product details',
                        product_quantity: [JSON.stringify(result.quantity)],
                        ga_action: 'Add to Cart | Customizer',
                        ga_label: result.pid
                    };
                    var pdpEventData = Object.assign(productProps, addCartObj);
                    tealium.triggerEvent(pdpEventData);
                });
            }
        });

        $(document).on(TRACKING_PRODUCTDETAIL, '.pdp-in-store-inventory .js-bopis-shipping-label', function () {
            productId = $('.container.product-detail.product-wrapper').data('pid');
            if (window.utag_data) {
                var tealObj = {
                    event_type: 'pick_up_store',
                    page_name: window.utag_data.page_name,
                    ga_action: 'Pick Up In Store',
                    ga_label: productId
                };
                tealium.triggerEvent(tealObj);
            }
        });

        $(document).on('store:selected', function (event, result) {
            if (window.utag_data) {
                var tealObj = {
                    event_type: 'pick_up_store_apply',
                    page_name: window.utag_data.page_name,
                    ga_action: 'Pick Up In Store',
                    ga_label: result && result.storeID ? result.storeID : null
                };
                tealium.triggerEvent(tealObj);
            }
        });

        /**
        * create object for bv analytics
        * @param {Object} data product data
        * @param {Object} loadData window utag data
        * @return {Object} bv object
        */
        function bvObject(data, loadData) {
            return {
                page_type: loadData.page_type !== undefined ? loadData.page_type : window.utag_data.page_type,
                page_name: window.utag_data.page_name,
                login_status: window.utag_data.login_status,
                ga_category: 'product reviews',
                ga_action: 'click',
                pdp_state: loadData.pdp_state,
                product_category: data.product_category,
                product_master: data.product_master,
                product_id: data.product_master,
                product_sku: data.product_sku,
                product_name: data.product_name,
                product_style_number: data.product_style_number,
                product_gender: data.product_gender,
                product_silhouette: data.product_silhouette,
                product_rating_score: $('.bv_avgRating_component_container').first().text(),
                product_review_count: $('.bv_numReviews_text').first().text(),
                in_stock_status: loadData.in_stock_status,
                pdp_availability: data.product_availability,
                item_brand: data.product_brand,
                item_category: data.product_category,
                item_id: data.product_id,
                item_name: data.product_name,
                item_variant: data.product_variant_id
            };
        }

        $(document).on('click', '.bv_ratings_summary, .bv_button_buttonFull', function () {
            if (window.utag_data) {
                productId = $('.container.product-detail.product-wrapper').data('pid');
                var productInfo = tealium.getProductDetails(productId);
                var loadData = window.utag_load_data;
                productInfo.then(function (data) {
                    var tealObj = bvObject(data, loadData);
                    tealObj.event_type = 'product_reviews_read';
                    tealObj.event_message = 'product_reviews_read_reviews';
                    tealObj.ga_label = 'read reviews';
                    tealObj.event_detail = 'read_reviews';
                    tealium.triggerEvent(tealObj);
                });
            }
        });

        $(document).on('bv:startreview', function (event, result) {
            if (window.utag_data) {
                productId = $('.container.product-detail.product-wrapper').data('pid');
                var productInfo = tealium.getProductDetails(productId);
                var loadData = window.utag_load_data;
                var rating = result + 1;
                productInfo.then(function (data) {
                    var tealObj = bvObject(data, loadData);
                    tealObj.event_type = 'product_reviews_start';
                    tealObj.event_message = 'product_reviews_start_review';
                    tealObj.ga_label = 'start review';
                    tealObj.event_detail = 'start_review';
                    tealObj.product_user_score = rating;
                    tealium.triggerEvent(tealObj);
                });
            }
        });

        $(document).on('bv:submitreview', function (event, result) {
            if (window.utag_data) {
                productId = $('.container.product-detail.product-wrapper').data('pid');
                var productInfo = tealium.getProductDetails(productId);
                var loadData = window.utag_load_data;
                productInfo.then(function (data) {
                    var tealObj = bvObject(data, loadData);
                    tealObj.event_type = 'product_reviews_submit';
                    tealObj.event_message = 'product_reviews_successful_submit';
                    tealObj.ga_label = 'successful submit';
                    tealObj.event_detail = 'review_successful_submit';
                    tealObj.product_user_score = result;
                    tealium.triggerEvent(tealObj);
                });
            }
        });

        $('.size-chart').click(function () {
            productId = $('.container.product-detail.product-wrapper').data('pid');
            var productInfo = tealium.getProductDetails(productId);
            var sizeChartModalTitle = $('.size-chart-container h2').text();
            var elementText = $(this).text().trim();
            var elementType = $(this)[0].nodeName;

            productInfo.then(function (data) {
                if (window.utag_data) {
                    var productProps = productCommonProps(data);
                    var sizeChartObject = {
                        event_detail: sizeChartModalTitle,
                        event_type: 'pdp_click',
                        sizefit_guide: 'yes',
                        element_type: elementType,
                        element_text: elementText
                    };
                    var sizeChartDataObject = $.extend({}, productProps, sizeChartObject);
                    var sizeChartEventData = Object.assign(window.utag_data, sizeChartDataObject);

                    tealium.triggerEvent(sizeChartEventData);
                }
            });
        });

        $('.product-detail .collapsible-lg a.detail-title').click(function () {
            productId = $('.container.product-detail.product-wrapper').data('pid');
            var productInfo = tealium.getProductDetails(productId);
            var accordionTitle = $(this).contents().get(0).nodeValue;
            var elementText = this.innerText;
            var elementType = $(this)[0].nodeName;

            productInfo.then(function (data) {
                if (window.utag_data) {
                    var productProps = productCommonProps(data);
                    var accordionObject = {
                        accordion_expand: accordionTitle,
                        element_type: elementType,
                        element_text: elementText,
                        event_type: 'pdp_click'
                    };
                    var accordionDataObject = $.extend({}, productProps, accordionObject);
                    var accordionEventData = Object.assign(window.utag_data, accordionDataObject);

                    tealium.triggerEvent(accordionEventData);
                }
            });
        });

        $(document).on(TRACKING_PRODUCTDETAIL, '.enable-bisn #bisn-modal-trigger', function () {
            productId = $('.container.product-detail.product-wrapper').data('pid');
            var productInfo = tealium.getProductDetails(productId);
            var elementText = $(this).text().trim();
            var elementType = $(this)[0].nodeName;

            productInfo.then(function (data) {
                if (window.utag_data) {
                    var productProps = productCommonProps(data);
                    var bisnObject = {
                        in_stock_alert: 'yes',
                        element_type: elementType,
                        element_text: elementText,
                        event_type: 'pdp_click'
                    };
                    var sizeChartDataObject = $.extend({}, productProps, bisnObject);
                    var sizeChartEventData = Object.assign(window.utag_data, sizeChartDataObject);

                    tealium.triggerEvent(sizeChartEventData);
                }
            });
        });

        /**
         * This will act as a helper function for the click events for the 'Why You'll love it' component.
         * @param {string} eventDetail3 - EventDetail3 value to be passed through - This will always be title card text from the element clicked on
         * @param {number} eventDetail4 - EventDetail4 value to be passed through - This will almost always be the number of the slide clicked on, between 1 and 5
         * @param {string} eventAction - This will be either 'click' or 'scroll', and will determine which event_message is sent
         */
        function sendWhyYoullLoveItAnalytics(eventDetail3, eventDetail4, eventAction) {
            if (window.utag_data) {
                var productInfo = tealium.getProductDetails(window.utag_data.product_id);
                productInfo.then(function (data) {
                    var productProps = productCommonProps(data);

                    productProps.event_detail = 'why_youll_love_it';
                    productProps.event_detail2 = eventAction;
                    productProps.event_detail3 = eventDetail3;
                    productProps.event_detail4 = eventDetail4;
                    productProps.event_message = eventAction === 'click' ? 'why_youll_love_it_details_expanded' : 'why_youll_love_it_scroll';
                    productProps.event_type = 'pdp_click';

                    var sliderCardEventData = $.extend({}, productProps);
                    tealium.triggerEvent(sliderCardEventData);
                });
            }
        }

        var cardTitleClasses = '.why-youll-love-it-container .slick-slide.slick-active .card-title';
        var slickSlideActiveSlideClasses = '.why-youll-love-it-container .slick-slide.slick-active';
        var slickIndexDataAttribute = 'data-slick-index';
        var positionDataAttribute = 'data-position';

        $(document).on(TRACKING_PRODUCTDETAIL, '.why-youll-love-it-container .card-title', function () {
            var eventDetail3 = $(this).text();
            var eventDetail4 = Number($(this).closest('.card').attr(positionDataAttribute)) + 1;

            sendWhyYoullLoveItAnalytics(eventDetail3, eventDetail4, 'click');
        });

        $(document).on(TRACKING_PRODUCTDETAIL, '.why-youll-love-it-container .horizontal-product-carousel .next-arrow', function () {
            var eventDetail3 = $(cardTitleClasses).last().text();
            var eventDetail4 = Number($(slickSlideActiveSlideClasses).last().attr(slickIndexDataAttribute)) + 1;

            sendWhyYoullLoveItAnalytics(eventDetail3, eventDetail4, 'scroll');
        });

        $(document).on(TRACKING_PRODUCTDETAIL, '.why-youll-love-it-container .horizontal-product-carousel .prev-arrow', function () {
            var eventDetail3 = $(cardTitleClasses).first().text();
            var eventDetail4 = Number($(slickSlideActiveSlideClasses).first().attr(slickIndexDataAttribute)) + 1;

            sendWhyYoullLoveItAnalytics(eventDetail3, eventDetail4, 'scroll');
        });

        $(document).on(TRACKING_PRODUCTDETAIL, '.why-youll-love-it-container .slick-dots', function () {
            var eventDetail3 = $(cardTitleClasses).first().text();
            var eventDetail4 = Number($(slickSlideActiveSlideClasses).first().attr(slickIndexDataAttribute)) + 1;

            sendWhyYoullLoveItAnalytics(eventDetail3, eventDetail4, 'scroll');
        });

        $(document).on(TRACKING_PRODUCTDETAIL, '.why-youll-love-it-container .slick-slide.slick-active .card-img-top', function () {
            var eventDetail3 = $(this).parents('.card').find('.card-text .card-title').text();
            var eventDetail4 = Number($(this).closest('.card').attr(positionDataAttribute)) + 1;

            sendWhyYoullLoveItAnalytics(eventDetail3, eventDetail4, 'click');
        });

        /**
         * This function when called, builds up the tealium object for apparel fit tool and triggers the event.
         * @param {Object} eventMessage type of event
         * @param {Object} eventDetail defines the interaction with the CTA
         * @param {Object} eventDetail2 defines the interaction with the CTA
         * @param {Object} eventDetail3 defines the interaction with the CTA
         * @param {Object} elem gets the instance of the element
         */
        function sendApparelToolAnalytics(eventMessage, eventDetail, eventDetail2, eventDetail3, elem) {
            var elementText = $(elem).text().trim();
            var elementType = $(elem)[0].nodeName;
            if (window.utag_data) {
                var productInfo = tealium.getProductDetails(window.utag_data.product_id);
                productInfo.then(function (data) {
                    var loadData = window.utag_load_data;
                    var productProps = productCommonProps(data);
                    var styleAttribute = 'data-attrvalue';
                    var styleNumber = $('.variation-attribute.style-attribute.selected').attr(styleAttribute);
                    var colorAttribute = 'data-variation-value';
                    var colorName = $('.variation-attribute.style-attribute.selected').attr(colorAttribute);
                    productProps.event_type = 'user_tool';
                    productProps.event_message = eventMessage;
                    productProps.event_detail = eventDetail;
                    productProps.event_detail2 = eventDetail2;
                    if (eventDetail3) {
                        productProps.event_detail3 = eventDetail3;
                    }
                    productProps.product_style_number = styleNumber;
                    productProps.product_color = colorName;

                    var boldMetricsObj = {
                        page_type: loadData.page_type !== undefined ? loadData.page_type : window.utag_data.page_type,
                        page_name: loadData.page_name,
                        login_status: loadData.login_status,
                        pdp_state: loadData.pdp_state,
                        site: loadData.site,
                        site_section: loadData.site_section,
                        page_group: loadData.page_type !== undefined ? loadData.page_type : window.utag_data.page_type,
                        page_context_type: loadData.page_context_type,
                        page_context_title: loadData.page_context_title,
                        site_type: loadData.site_type,
                        ga4_event: 'user_tool',
                        element_type: elementType,
                        element_text: elementText,
                        user_id: loadData.user_id,
                        in_stock_status: loadData.in_stock_status,
                        pdp_availability: data.product_availability,
                        item_brand: data.product_brand,
                        item_id: data.product_id,
                        item_name: data.product_name,
                        item_variant: data.product_variant_id
                    };

                    var boldMetricsEvent = Object.assign(productProps, boldMetricsObj);
                    tealium.triggerEvent(boldMetricsEvent);
                });
            }
        }

        /**
         * This function gets triggered when Find My Size is clicked
         */
        $(document).on(TRACKING_PRODUCTDETAIL, '.boldmetrics-ssc-button', function () {
            sendApparelToolAnalytics('apparel_fit_tool_find_my_size', 'apparel_fit_tool', 'find_my_size_click', '', this);
        });

        /**
         * This function gets triggered when the modal is closed by clicking on x
         */
        $(document).on('click', '.MuiContainer-root .svg-inline--fa.fa-xmark', function () {
            sendApparelToolAnalytics('apparel_fit_tool_close_modal', 'apparel_fit_tool', 'close_modal', '', this);
        });

        /**
         * This function gets triggered when the measurements are submitted
         */
        $(document).on('click', '.sc-kYxDKI.hwThyZ.MuiBox-root', function () {
            sendApparelToolAnalytics('apparel_fit_tool_submit', 'apparel_fit_tool', 'submit', '', this);
        });

        /**
         * This function gets triggered when you select a size from recommended sizes
         */
        $(document).on('click', '.sc-fTFjTM.bfqKYZ', function () {
            sendApparelToolAnalytics('apparel_fit_tool_explore_fit', 'apparel_fit_tool', 'explore_fit', $(this).text().trim(), this);
        });

        /**
         * This function gets triggered when the the user decides to continue with a recommended size
         */
        $(document).on('click', '.sc-ehixzo.iRrtmg', function () {
            sendApparelToolAnalytics('apparel_fit_tool_continue_shopping', 'apparel_fit_tool', 'continue_shopping', '', this);
        });
    }
};

productDetails.init();
