/* eslint-disable no-undef */

'use strict';

var tealium = require('./tealium');
var TRACKING_WISHLIST = 'tracking:wishlist';

/**
 * Get wish list product details to send in analytics calls.
 * @param {string} pid - filter the products based on pid.
 * @param {string} eventType - dynamically binding.
 * @param {string} gaAction - dynamically binding.
 * @param {boolean} label - if true send product id otherwise not.
 */
function getWishlistProductDetails(pid, eventType, gaAction, label) {
    var url = $('.wishlist-api').data('wishlisturl') + '?pid=' + pid;
    $.ajax({
        url: url,
        type: 'POST',
        dataType: 'json',
        success: function (data) {
            var productInfo = data.productInfo;
            var productArray = {
                product_name: [],
                product_brand: [],
                product_unit_price: [],
                product_sku: [],
                product_style_number: [],
                adobe_product_id: [],
                product_id: [],
                product_master: [],
                product_category: [],
                product_subcategory: [],
                product_quantity: [],
                product_vat: [],
                product_gender: [],
                product_color: [],
                product_size: [],
                product_width: [],
                product_gbu: [],
                product_line: [],
                product_type: [],
                product_silhouette: [],
                original_product_unit_price: [],
                category_path: []
            };

            if (productInfo) {
                productArray.product_brand.push(productInfo.product_brand);
                productArray.product_name.push(productInfo.product_name);
                productArray.product_unit_price.push(productInfo.product_unit_price);
                productArray.product_sku.push(productInfo.product_sku);
                productArray.product_style_number.push(productInfo.product_style_number);
                productArray.adobe_product_id.push(productInfo.adobe_product_id);
                productArray.product_id.push(productInfo.product_master);
                productArray.product_master.push(productInfo.product_master);
                productArray.product_category.push(productInfo.product_category);
                productArray.product_subcategory.push(productInfo.product_subcategory);
                productArray.product_quantity.push(productInfo.product_quantity);
                productArray.product_vat.push(productInfo.product_vat);
                productArray.product_gender.push(productInfo.product_gender);
                productArray.product_color.push(productInfo.product_color);
                productArray.product_size.push(productInfo.product_size);
                productArray.product_width.push(productInfo.product_width);
                productArray.product_gbu.push(productInfo.product_gbu);
                productArray.product_line.push(productInfo.product_line);
                productArray.product_type.push(productInfo.product_type);
                productArray.product_silhouette.push(productInfo.product_silhouette);
                productArray.original_product_unit_price.push(productInfo.original_product_unit_price);
                productArray.category_path.push(productInfo.category_path);
            }
            if (window.utag_data && productInfo) {
                var removeItemObj = {
                    event_type: eventType,
                    page_name: window.utag_data.page_name,
                    adobe_user_flow: window.utag_data.user_flow,
                    product_name: productArray.product_name,
                    product_brand: productArray.product_brand,
                    product_unit_price: productArray.product_unit_price,
                    product_sku: productArray.product_sku,
                    product_style_number: productArray.product_style_number,
                    adobe_product_id: productArray.adobe_product_id,
                    product_id: productArray.product_master,
                    product_master: productArray.product_master,
                    product_category: productArray.product_category,
                    product_subcategory: productArray.product_subcategory,
                    product_quantity: productArray.product_quantity,
                    product_vat: productArray.product_vat,
                    product_gender: productArray.product_gender,
                    product_color: productArray.product_color,
                    product_size: productArray.product_size,
                    product_width: productArray.product_width,
                    product_gbu: productArray.product_gbu,
                    product_line: productArray.product_line,
                    product_type: productArray.product_type,
                    product_silhouette: productArray.product_silhouette,
                    ga_action: gaAction,
                    ga_label: label === false ? pid : '',
                    category_path: productArray.category_path,
                    original_product_unit_price: productArray.original_product_unit_price
                };
                tealium.triggerEvent(removeItemObj);
            }
        },
        error: function () {
            return {};
        }
    });
}
module.exports = {
    init: function () {
        $('.wishlist-main .share-wishlist #share-wishlist-link').on(TRACKING_WISHLIST, function () {
            if (window.utag_data) {
                var shareObj = {
                    event_type: 'wishlist_share',
                    page_name: window.utag_data.page_name,
                    ga_action: 'Wishlist | Share',
                    ga_label: ''
                };
                tealium.triggerEvent(shareObj);
            }
        });

        $(document).on(TRACKING_WISHLIST, '.wishlistItemCards .remove-from-wishlist', function () {
            var productId = $(this).attr('data-url').split('?pid=')[1];
            getWishlistProductDetails(productId, 'wishlist_remove', 'Wishlist | Remove', false);
        });

        // My account remove wishlist product
        $('body').on('account:removeWishlistItem', function (event, productId) {
            getWishlistProductDetails(productId, 'wishlist_remove', 'Wishlist | Remove', true);
        });

        $(document).on(TRACKING_WISHLIST, '.wishlistItemCards .add-to-cart-wishList .add-to-cart', function () {
            var productId = $(this).attr('data-pid');
            getWishlistProductDetails(productId, 'cart_add_wishlist', 'Add to Cart | Wishlist', false);
        });

        // My account add to cart
        $(document).on(TRACKING_WISHLIST, '.wishlist-account-card .add-to-cart-wishList .add-to-cart', function () {
            var productId = $(this).attr('data-pid');
            getWishlistProductDetails(productId, 'cart_add_my_account', 'Add to Cart | My Account', false);
        });

        $(document).on(TRACKING_WISHLIST, '.edit-wishlist-modal .btn-add-to-cart-wishlist', function () {
            var productId = $('.edit-wl-product-id').text();
            getWishlistProductDetails(productId, 'cart_add_wishlist', 'Add to Cart | Wishlist', false);
        });
    }
};
