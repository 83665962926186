/* eslint-disable require-jsdoc */

'use strict';

var utils = require('../pages/utils');

module.exports = {
    init: function () {
        $(document).on('accountVerification:resendCodeSuccess', function () {
            var eventObj = {
                event_type: 'account_verification',
                event_message: 'resend_verification_code'
            };
            utils.fireTealium(eventObj);
        });

        $(document).on('accountVerification:verifySuccess', function () {
            var eventObj = {
                event_type: 'account_verification',
                event_message: 'account_verification_success'
            };
            utils.fireTealium(eventObj);
        });

        $(document).on('accountVerification:cancel', function () {
            var eventObj = {
                event_type: 'account_verification',
                event_message: 'account_verification_cancel'
            };
            utils.fireTealium(eventObj);
        });
    }
};
