/* eslint-disable no-undef */

'use strict';

var tealium = require('../pages/tealium');

var countryCheck = {
    init: function () {
        $(document).on('preferences:select-region', function (_, data) {
            if (window.utag_data) {
                var utagData = window.utag_data;
                var selectRegionEvent = {
                    site: utagData.site,
                    site_section: utagData.site_section,
                    page_name: utagData.page_name,
                    page_group: utagData.page_group,
                    page_context_type: utagData.page_context_type,
                    page_context_title: utagData.page_context_title,
                    login_status: utagData.login_status,
                    site_type: utagData.site_type,
                    element_type: data.element_type,
                    element_text: data.element_text,
                    user_id: utagData.user_id,
                    event_type: 'select_region',
                    selected_country: data.selected_country,
                    previous_country: data.previous_country
                };

                tealium.triggerEvent(selectRegionEvent, 'link');
            }
        });
    }
};

countryCheck.init();
