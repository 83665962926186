/* eslint-disable no-undef */
/* eslint-disable consistent-return */
/* eslint-disable no-prototype-builtins */
/* eslint camelcase: [0, {properties: never}] */

'use strict';

var tealium = require('./../pages/tealium');
var productIdSelector = $('.container.product-detail.product-wrapper').data('pid');
var carouselRootElementSelector = '.shop-the-look-gallery-carousel';
var styliticContainerID = '[id^="stylitics-container"]';
var productData = {
    in_stock_status: '',
    pdp_availability: '',
    item_brand: '',
    item_category: '',
    item_id: '',
    item_name: '',
    item_variant: ''
};

var carouselGallerySlugAttr = 'gallery-slug';
/**
 * Common analytics data used in chat events
 * @returns {Object} having common properties used across chat modal events
 */
function commonAnalyticsData() {
    if (window.utag_data) {
        return {
            site: window.utag_data.site,
            site_section: window.utag_data.site_section,
            page_name: window.utag_data.page_name,
            page_group: window.utag_data.page_type,
            page_context_type: window.utag_data.page_context_type,
            page_context_title: window.utag_data.page_context_title,
            page_location: window.location.pathname,
            site_type: window.utag_data.site_type,
            login_status: window.utag_data.login_status,
            user_id: window.utag_data.user_id,
            ga4_event: 'user_tool'
        };
    }
}

/**
 * Common product information for analytics data
 * @param {Object} eventObj - Analytics event related data from each event
 */
function commonProductData(eventObj) {
    var commonProps = commonAnalyticsData();
    var pid = $(productIdSelector) || '';
    if (pid !== '') {
        var productInfo = tealium.getProductDetails(pid);
        productInfo.then(function (data) {
            productData = {
                in_stock_status: data.product_availability && data.product_availability[0] === 'IN_STOCK' ? 'in_stock' : 'out_of_stock',
                item_brand: data.product_brand,
                item_category: data.product_category,
                item_id: data.product_id,
                item_name: data.product_name,
                item_variant: data.product_sku,
                pdp_availability: data.product_availability
            };
            var styliticsEventData = Object.assign(commonProps, eventObj, productData);
            tealium.triggerEvent(styliticsEventData);
        });
    } else {
        var styliticsEventData = Object.assign(commonProps, eventObj, productData);
        tealium.triggerEvent(styliticsEventData);
    }
}

/**
 * Additional Analytics event data
 * @param {Object} $styliticParentCont - Jquery dom object of stylitic root element.
 * @param {string} fullGalleryGridEventName - custom event name for analytics
 * @param {string} galleryCarouselEventName - custom event name for analytics
 * @returns {Object} having common properties used across chat modal events
 */
function setAdditionalEventData($styliticParentCont, fullGalleryGridEventName, galleryCarouselEventName) {
    var data = {};
    if ($styliticParentCont.hasClass('shop-the-look-gallery-carousel')) {
        data.eventType = fullGalleryGridEventName;
        data.eventDetail2 = 'carousel';
        data.slugID = $styliticParentCont.data('slug-id');
    } else if ($styliticParentCont.hasClass('shop-the-look-gallery') && $styliticParentCont.hasClass('full-gallery')) {
        data.eventType = galleryCarouselEventName;
        data.eventDetail2 = 'grid';
        data.slugID = $styliticParentCont.data(carouselGallerySlugAttr);
    }
    return data;
}

/**
 * GA Analytics event for shop the look carousel next and previous button click
 */
function shopTheLookGalleryCarouselNextPrevClick() {
    var carousels = document.querySelectorAll(carouselRootElementSelector);
    carousels.forEach(function (element) {
        element.addEventListener('click', function (e) {
            var $element = $(e.target);
            var $parentCont = $element.closest(carouselRootElementSelector);
            var label = '';
            var eventType = '';
            var scrollDirection = '';
            if ($element.hasClass('stylitics-arrow-btn') || $element.parents('.stylitics-arrow-btn').length > 0) {
                if ($element.hasClass('stylitics-next') || $element.parents('.stylitics-next').length > 0) {
                    eventType = 'stylitics_gallery_carousel_forward';
                    label = 'next';
                    scrollDirection = 'scroll_forward';
                } else {
                    eventType = 'stylitics_gallery_carousel_backward';
                    label = 'previous';
                    scrollDirection = 'scroll_backward';
                }
                var eventObj = {
                    element_type: $element[0].nodeName,
                    element_text: label,
                    event_type: eventType,
                    event_detail: 'stylitics_gallery',
                    event_detail2: 'carousel',
                    event_detail3: scrollDirection,
                    event_detail4: $parentCont.data('slug-id')
                };
                commonProductData(eventObj);
            }
        }, true);
    });
}

/**
 * GA Analytics event for shop the look gallery grid page load
 */
function shopTheLookGalleryCarouselLoaded() {
    $(document).on('tealium:StyliticGalleryCarouselComponentLoaded', function (_event, element) {
        var $parentCont = $(element).closest(carouselRootElementSelector);
        var eventObj = {
            element_type: '',
            element_text: '',
            event_type: 'stylitics_gallery_carousel_load',
            event_detail: 'stylitics_gallery',
            event_detail2: 'carousel',
            event_detail3: 'load',
            event_detail4: $parentCont.data('slug-id')
        };
        commonProductData(eventObj);
    });
}

/**
 * GA Analytics event for shop the look carousel page load
 */
function shopTheLookGridLoaded() {
    $(document).on('tealium:StyliticFullGalleryComponentLoaded', function (_event, element) {
        var $parentCont = $(element).closest('.shop-the-look-gallery');
        var eventObj = {
            element_type: '',
            element_text: '',
            event_type: 'stylitics_gallery_grid_load',
            event_detail: 'stylitics_gallery',
            event_detail2: 'grid',
            event_detail3: 'load',
            event_detail4: $parentCont.data(carouselGallerySlugAttr)
        };
        commonProductData(eventObj);
    });
}

/**
 * GA Analytics event for view details CTA click.
 */
function styliticViewDetailsCTA() {
    $(document).on('click', '.stylitics-bundle-footer .stylitics-view-item-details-cta, .stylitics-collage-container img', function (e) {
        setTimeout(function () {
            var $currentViewDetailsCTA = $(e.target);
            var $styliticParentCont = $currentViewDetailsCTA.parents(styliticContainerID);
            var $styliticsBundleContainer = $currentViewDetailsCTA.closest('.stylitics-classic-bundlecard-wrap').find('.stylitics-bundle-container');
            var $itemsList = $styliticsBundleContainer.find('.stylitics-items-list li');
            var productIDArray = [];
            $itemsList.each(function (index) {
                productIDArray.push($($itemsList[index]).data('stylitics-client-colorway-id'));
            });
            var customEventData = setAdditionalEventData($styliticParentCont, 'stylitics_gallery_carousel_load_select_image', 'stylitics_gallery_grid_load_select_image');
            var eventObj = {
                element_type: $currentViewDetailsCTA[0].nodeName,
                element_text: $currentViewDetailsCTA.text().trim(),
                event_type: customEventData.eventType,
                event_detail: 'stylitics_gallery',
                event_detail2: customEventData.eventDetail2,
                event_detail3: 'view_details',
                event_detail4: productIDArray,
                event_detail5: customEventData.slugID
            };
            commonProductData(eventObj);
        }, 0);
    });
}

/**
 * GA Analytics event for view details CTA click.
 */
function styliticShopCTA() {
    var $parent = $(styliticContainerID);
    $parent.each(function () {
        this.addEventListener('click', function (event) {
            // Get the target element
            var target = event.target;
            // If the target element is a child of the parent element, log its class name
            if (target.classList.contains('stylitics-cta-link')) {
                var $currentViewDetailsCTA = $(target);
                var $styliticParentCont = $currentViewDetailsCTA.parents(styliticContainerID);
                var productName = $currentViewDetailsCTA.attr('href').split('/').pop().match(/^[^.]+/g) || '';

                var customEventData = setAdditionalEventData($styliticParentCont, 'stylitics_gallery_carousel_shop_now', 'stylitics_gallery_grid_shop_now');
                var eventObj = {
                    element_type: $currentViewDetailsCTA[0].nodeName,
                    element_text: $currentViewDetailsCTA.text().trim(),
                    event_type: customEventData.eventType,
                    event_detail: 'stylitics_gallery',
                    event_detail2: customEventData.eventDetail2,
                    event_detail3: 'shop_details',
                    event_detail4: productName,
                    event_detail5: customEventData.slugID
                };

                commonProductData(eventObj);
            }
        }, true);
    });
}

module.exports = {
    shopTheLookGalleryCarouselNextPrevClick: shopTheLookGalleryCarouselNextPrevClick,
    shopTheLookGalleryCarouselLoaded: shopTheLookGalleryCarouselLoaded,
    shopTheLookGridLoaded: shopTheLookGridLoaded,
    styliticViewDetailsCTA: styliticViewDetailsCTA,
    styliticShopCTA: styliticShopCTA
};
